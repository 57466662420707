a {
    transition: color .2s ease-in-out, background .2s ease-in-out, border .2s ease-in-out;
    font-weight: bold;
    color: @font-colour-link;
    text-decoration: none;

    &:hover {
        color: darken(@font-colour-link, 10%);
        text-decoration: none;
    }
}

p {
    margin: 0;
    padding: 0;

    line-height: @line-height;

    small {

    }

    strong {
        font-weight: bold;
    }

}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    font-family: @font-family-heading;
    color: @font-colour-heading;
    line-height: 1.1;
    small {
        display: inline-block;
        &:before {
            content: ' ';
        }
    }
    > a, a > & {
        color: @font-colour-link;
        text-decoration: none;
        font-weight: inherit;
    }
}

h1 { font-size: @font-size-h1; }
h2 { font-size: @font-size-h2; }
h3 { font-size: @font-size-h3; }
h4 { font-size: @font-size-h4; }
h5 { font-size: @font-size-h5; }
h6 { font-size: @font-size-h6; }

blockquote {
    font-style: italic;
    font-size: 1.2em;

    margin: 0;
    padding: 0.5em 0;
    line-height: 1.4;
    border: none;

    p {
        position: relative;
        &::before {
            margin-top: -10px;
            padding-right: 5px;
            display: inline;
            float: left;
            content: url('/assets/img/blockquote_left_sm.png');
        }
        &::after {
            display: inline;
            float: right;
            content: url('/assets/img/blockquote_right_sm.png');
        }
        margin-bottom: 2em;
        &:last-child {
            margin-bottom: 0;
        }
    }

    cite {
        clear: both;
        display: block;
        margin-top: 1em;
        padding: 0 1em;
        text-align: right;
        font-style: normal;
        font-weight: bold;
        &:before {
            content: '– ';
        }
    }

    @media screen and (max-width: @screen-md) {
        font-size: 1.2em;
    }

    @media screen and (max-width: @screen-md) {
        font-size: 1em;
    }
}

ul, ol {
    margin: 0;
    padding: 0;

    li {
        margin-bottom: 0.5em;
        margin-left: 1em;
    }
}

hr {
    border: none;
    border-top: @border-default;
}
