// Use to hide or display items depending on JS support

// JS enabled
.u-noJsShow {
    display: none !important;
}

// JS disabled
.no-js {

    .u-noJsShow {
        display: block !important;
    }

    .u-noJsHide {
        display: none !important;
    }
}

// Screen Reader Only
.u-sr {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;  /* 2 */
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;            /* 3 */
}
