* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body, html {
    margin: 0;
    padding: 0;
    background-color: @colour-background;

    font-family: @font-family;
    color: @font-colour;
    font-size: @font-size;
    line-height: @line-height;

    height: 100%;
    scroll-behavior: smooth;
}

body.public {
    animation: bodyfadein 1s;
}

@keyframes bodyfadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

// All images should be responsive by default
img {
    display: block;
	border: none;
	max-width: 100%; // Part 1: Set a maximum relative to the parent
	height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching
}
